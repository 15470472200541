import { defineNuxtRouteMiddleware } from '#app'

export default defineNuxtRouteMiddleware(async () => {
  const $auth = useAuth()
  const user = await $auth.actions.profile()

  if (!user) {
    return navigateTo('/')
  }
})
